<script setup lang="ts">
interface Props {
  block?: boolean | undefined;
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | null | undefined;
  rounded?: boolean | undefined;
  className?: String | string | undefined;
  [x: string]: any;
};

const props = withDefaults(defineProps<Props>(), {
  rounded: true
});

const styleSize = computed<String | string>(() => ((props.size !== undefined) ? props.size : useNuxtApp()?.$styleSize?.value));

const sizes = {
  '2xl': 'px-8 py-4',
  xl: 'px-6 py-3',
  lg: 'px-5 py-2.5',
  md: 'px-4 py-2',
  sm: 'px-2 py-1',
  null: ''
};
</script>

<template>
  <div :class="[
    (block ? 'w-full' : 'w-fit'),
    sizes[styleSize],
    (rounded && ((styleSize && (styleSize != 'sm')) ? ('rounded-' + styleSize) : 'rounded')),
    (styleSize && ('text-' + styleSize)),
    (className)
  ]">
    <slot />
  </div>
</template>

<script setup lang="ts">
const cookie = useCookie<CookieConsentBase>((useRuntimeConfig()?.public?.cookie?.name ?? 'consent'));
</script>

<template>
  <div class="z-[90] fixed bottom-4 right-4 flex flex-col justify-center items-end gap-4">
    <BaseText @click="(e: Event) => (cookie = null)" size="xl"
      class="drop-shadow-lg hover:drop-shadow-2xl scale-[1.0] hover:scale-[1.5] [&&]:duration-200 cursor-pointer hover:mb-1 hover:mr-1">
      🍪
    </BaseText>
  </div>
</template>

<script setup lang="ts">
interface Props {
  title?: String | string | undefined;
  type?: 'select' | 'textarea' | 'checkbox' | 'text' | 'email' | 'url' | 'tel' | 'number' | 'range' | 'radio' | 'color' | 'date' | 'month' | 'week' | 'time' | 'datetime-local' | 'password' | 'submit' | 'reset';
  styleName?: 'outline' | 'primary' | 'secondary' | 'default' | 'inverted' | 'muted' | null | undefined;
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | null | undefined;
  value?: any | null | undefined;
  state?: boolean | null | undefined;
  name?: String | string | undefined;
  stringify?: boolean | undefined;
  errors?: Object | Array<String | string> | undefined;
  block?: boolean | undefined;
  min?: Number | number | undefined;
  max?: Number | number | undefined;
  step?: Number | number | undefined;
  minlength?: Number | number | undefined;
  maxlength?: Number | number | undefined;
  trueValue?: String | string | undefined;
  falseValue?: String | string | undefined;
  placeholder?: String | string | undefined;
  multiple?: boolean | undefined;
  required?: boolean | undefined;
  disabled?: boolean | undefined;
  hidden?: boolean | undefined;
  options?: Array<OptionItem> | undefined,
  optionNullSkip?: boolean | undefined,
  plain?: boolean | undefined,
  className?: String | string | undefined;
  suggested?: String | string | undefined;
  autocomplete?: String | string | undefined;
  content?: '✔' | 'X' | String | string | undefined;
  rounded?: boolean | undefined;
  [x: string]: any;
};

const props = withDefaults(defineProps<Props>(), {
  trueValue: '1',
  falseValue: '0',
  step: 1,
  styleName: 'default',
  rounded: true,
  state: null,
  disabled: false,
  stringify: true,
  content: '✔'
});

const styleSize = computed<String | string>(() => ((props.size !== undefined) ? props.size : useNuxtApp()?.$styleSize?.value));

const model = defineModel({ required: false, default: null });

if (props.value !== undefined)
  model.value = props.value;
if (((props.type == 'select') || (props.type == 'checkbox')) && props.multiple && ((model.value === null) || (model.value === undefined)))
  model.value = [];

const emit = defineEmits(['click', 'change', 'input', 'blur']);

const sizes = {
  '2xl': 'px-8 py-4',
  xl: 'px-6 py-3',
  lg: 'px-5 py-2.5',
  md: 'px-4 py-2',
  sm: 'px-2 py-1',
  null: ''
};

const styles = {
  outline: 'text-slate-500 duration-500 ease-in-out placeholder:text-slate-500',
  primary: 'text-primary duration-500 ease-in-out placeholder:text-slate-500',
  secondary: 'text-secondary duration-500 ease-in-out placeholder:text-slate-500',
  default: 'text-slate-500 duration-500 ease-in-out placeSholder:text-slate-500',
  inverted: 'text-slate-500 duration-500 ease-in-out placeholder:text-slate-500',
  muted: 'text-slate-500 duration-500 ease-in-out placeholder:text-slate-500',
  null: ''
};

const states = {
  null: 'border-slate-200',
  true: 'border-green-500',
  false: 'border-red-500'
};

const contentComp = computed<String | string>(() => {
  //return 'before:content-["' + props.content + '"]'; Makes it into broken component
  let result = 'before:content-["✔"]'
  if (props.content)
    result = result.replace('✔', props.content);
  return result;
});

const optionsComp = computed<Array<OptionItem>>(() => {
  if (!props.optionNullSkip && !props?.options?.find((option: OptionItem) => (option.value === null)))
    return [{ value: null, text: (props.required ? 'Zvoľte možnosť!' : 'Ak je možné, vyberte.'), disabled: (props?.required ?? false) }].union(props.options);
  return props.options;
});

const pwdVisible = ref<boolean>(false);
</script>

<template>
  <div v-if="(!hidden || errors)" class="grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-1 items-center">
    <slot v-if="!plain" name="title">
      <label v-if="(title && (type != 'hidden') && !hidden)" class="col-span-1 font-medium">
        <BaseText :block="block" :size="styleSize" :disabled="disabled" :style-name="styleName">
          {{ title }}<b v-show="required" class="px-2 text-red-500">*</b>
        </BaseText>
      </label>
    </slot>
    <div :class="[
      ((!plain && title && (type != 'hidden') && !hidden) && 'col-start-2'),
      ((!plain && (type != 'hidden') && !hidden) && 'col-span-full h-full flex flex-col gap-1'),
      ((type == 'password') && ('relative'))
    ]">
      <select v-if="((type == 'select') && !hidden)" :name="((name && !stringify) ? name : null)" :class="[
        ('outline-none border-2 h-full'),
        (block ? 'w-full' : 'w-fit'),
        sizes[styleSize],
        (rounded && ((styleSize && (styleSize != 'sm')) ? ('rounded-' + styleSize) : 'rounded')),
        (styleSize && ('text-' + styleSize)),
        (!disabled && styles[styleName]),
        (!disabled ? 'focus:ring-4 ring-slate-200 cursor-pointer' : 'bg-slate-100'),
        (disabled && styles['muted']),
        states[state],
        className
      ]" :placeholder="placeholder" :multiple="multiple" :required="required" :disabled="disabled" v-model="model"
        :suggested="suggested" :autocomplete="autocomplete" @click="(e: Event) => (!disabled ? emit('click', e) : null)"
        @change="(e: Event) => (!disabled ? emit('change', e) : null)"
        @blur="(e: Event) => (!disabled ? emit('blur', e) : null)"
        @input="(e: Event) => (!disabled ? emit('input', e) : null)">
        <slot name="options">
          <option v-for="option in optionsComp" :key="((option.value !== undefined) ? option.value : option)"
            :value="((option.value !== undefined) ? option.value : option)" :disabled="option.disabled"
            v-text="(option.text ?? ((option.value !== undefined) ? option.value : option))" />
        </slot>
      </select>
      <textarea v-else-if="((type == 'textarea') && !hidden)" :name="((name && !stringify) ? name : null)" :class="[
        ('outline-none border-2 h-full'),
        (block ? 'w-full' : 'w-fit'),
        sizes[styleSize],
        (rounded && ((styleSize && (styleSize != 'sm')) ? ('rounded-' + styleSize) : 'rounded')),
        (styleSize && ('text-' + styleSize)),
        (!disabled && styles[styleName]),
        (!disabled ? 'focus:ring-4 ring-slate-200 cursor-pointer' : 'bg-slate-100'),
        (disabled && styles['muted']),
        states[state],
        className
      ]" :minlength="minlength" :maxlength="maxlength" :placeholder="placeholder" :required="required"
        :disabled="disabled" v-model="model" :suggested="suggested" :autocomplete="autocomplete"
        @click="(e: Event) => (!disabled ? emit('click', e) : null)"
        @change="(e: Event) => (!disabled ? emit('change', e) : null)"
        @blur="(e: Event) => (!disabled ? emit('blur', e) : null)"
        @input="(e: Event) => (!disabled ? emit('input', e) : null)">
      </textarea>
      <div v-else-if="((type == 'checkbox') && !hidden && multiple)" v-for="option in options"
        :key="((option.value !== undefined) ? option.value : option)" class="flex flex-row gap-2 items-center">
        <input :class="[
          ('outline-none border-2 h-fit w-fit'),
          sizes[styleSize],
          (rounded && ((styleSize && (styleSize != 'sm')) ? ('rounded-' + styleSize) : 'rounded')),
          (styleSize && ('text-' + styleSize)),
          (!disabled && styles[styleName]),
          (!disabled ? 'focus:ring-4 ring-slate-200 cursor-pointer' : 'bg-slate-100'),
          (disabled && styles['muted']),
          ('font-serif content-none outline-none before:text-transparent checked:before:text-current'),
          contentComp,
          states[state],
          className
        ]" type="checkbox" :true-value="option.value" :false-value="null" :placeholder="placeholder"
          :checked="model?.includes(option.value)" :required="(required && !option.disabled)"
          :disabled="(option.disabled || disabled)" :suggested="suggested" :autocomplete="autocomplete"
          @click="(e: Event) => (!(option.disabled || disabled) ? emit('click', e) : null)"
          @change="(e: Event) => (!(option.disabled || disabled) ? ((model = (model?.includes(option.value) ? model?.diff([option.value]) : model?.union([option.value]))), emit('change', e)) : null)"
          @blur="(e: Event) => (!(option.disabled || disabled) ? emit('blur', e) : null)"
          @input="(e: Event) => (!(option.disabled || disabled) ? emit('input', e) : null)">
        <BaseText :block="block" :size="styleSize" :disabled="(option.disabled || disabled)" :style-name="styleName">
          {{ (option.text ?? ((option.value !== undefined) ? option.value : option)) }}
        </BaseText>
      </div>
      <input v-else-if="((type == 'checkbox') && !hidden)" :name="((name && !stringify) ? name : null)" :class="[
        ('outline-none border-2 h-fit w-fit'),
        sizes[styleSize],
        (rounded && ((styleSize && (styleSize != 'sm')) ? ('rounded-' + styleSize) : 'rounded')),
        (styleSize && ('text-' + styleSize)),
        (!disabled && styles[styleName]),
        (!disabled ? 'focus:ring-4 ring-slate-200 cursor-pointer' : 'bg-slate-100'),
        (disabled && styles['muted']),
        ('font-serif content-none outline-none before:text-transparent checked:before:text-current'),
        contentComp,
        states[state],
        className
      ]" type="checkbox" :true-value="trueValue" :false-value="falseValue" :placeholder="placeholder"
        :required="required" :disabled="disabled" v-model="model" :suggested="suggested" :autocomplete="autocomplete"
        @click="(e: Event) => (!disabled ? emit('click', e) : null)"
        @change="(e: Event) => (!disabled ? emit('change', e) : null)"
        @blur="(e: Event) => (!disabled ? emit('blur', e) : null)"
        @input="(e: Event) => (!disabled ? emit('input', e) : null)">
      <input v-else-if="!hidden" :name="((name && !stringify) ? name : null)" :class="[
        ('outline-none border-2 h-full'),
        (block ? 'w-full' : 'w-fit'),
        sizes[styleSize],
        (rounded && ((styleSize && (styleSize != 'sm')) ? ('rounded-' + styleSize) : 'rounded')),
        (styleSize && ('text-' + styleSize)),
        (!disabled && styles[styleName]),
        (!disabled ? 'focus:ring-4 ring-slate-200 cursor-pointer' : 'bg-slate-100'),
        (disabled && styles['muted']),
        states[state],
        className
      ]" :type="(((type == 'password') && pwdVisible) ? 'text' : type)" :min="min" :max="max" :step="step"
        :minlength="minlength" :maxlength="maxlength" :true-value="trueValue" :false-value="falseValue"
        :placeholder="placeholder" :multiple="multiple" :required="required" :disabled="disabled" v-model="model"
        :suggested="suggested" :autocomplete="autocomplete" @click="(e: Event) => (!disabled ? emit('click', e) : null)"
        @change="(e: Event) => (!disabled ? emit('change', e) : null)"
        @blur="(e: Event) => (!disabled ? emit('blur', e) : null)"
        @input="(e: Event) => (!disabled ? emit('input', e) : null)">
      <button v-if="(type == 'password')" type="button"
        class="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-slate-500 focus:outline-none"
        @click="pwdVisible = !pwdVisible">
        <svg class="shrink-0 size-3.5" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
          stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <path v-show="((pwdVisible == null) || (pwdVisible == false))" d="M9.88 9.88a3 3 0 1 0 4.24 4.24" />
          <path v-show="((pwdVisible == null) || (pwdVisible == false))"
            d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68" />
          <path v-show="((pwdVisible == null) || (pwdVisible == false))"
            d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61" />
          <line v-show="((pwdVisible == null) || (pwdVisible == false))" x1="2" x2="22" y1="2" y2="22" />
          <path v-show="pwdVisible" d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
          <circle v-show="pwdVisible" cx="12" cy="12" r="3" />
        </svg>
      </button>
      <input v-if="(name && stringify)" :name="name" :value="JSON.stringify(model)" hidden :required="required" />
      <slot v-if="!plain" name="errors">
        <BaseInputError :name="name" :errors="errors" />
      </slot>
    </div>
  </div>
  <input v-else-if="(name && stringify && hidden)" :name="name" :value="JSON.stringify(model)" hidden
    :required="required" />
</template>

<style lang="scss" scoped>
input[type=checkbox] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

input[type=file] {
  &::file-selector-button {
    @apply bg-black text-white duration-500 ease-in-out border-transparent hover:bg-slate-600 font-medium rounded px-1 py-0.5 mr-2 sm:px-2 sm:py-1 sm:mr-4;
  }
}
</style>

<script setup lang="ts">
interface Props {
  showDefaultClose?: boolean | undefined;
  bgClickClose?: boolean | undefined;
  value?: boolean | null | undefined;
  [x: string]: any;
};

const props = withDefaults(defineProps<Props>(), {
  showDefaultClose: false,
  bgClickClose: false
});

const slots = useSlots();

const emit = defineEmits(['click', 'change']);
const model = defineModel<boolean>({ required: false, default: false });

if (props.value !== undefined)
  model.value = props.value;
</script>

<!-- Use preprocessors via the lang attribute! e.g. <template lang="pug"> -->
<template>
  <transition name="fade">
    <div v-if="model" class="fixed flex z-50 inset-0 overflow-y-auto justify-center items-center" @click="(e: Event) => emit('click', e)">
      <slot name="background">
        <span :class="[
            'fixed inset-0 bg-slate-600 transition-opacity opacity-75',
            (((isSlotEmpty(slots?.default) && !slots.box) || bgClickClose) && 'cursor-pointer')
          ]"
          @click="(e: Event) => (((isSlotEmpty(slots?.default) && !slots.box) || bgClickClose) ? (model = false, emit('change', e)) : null)"/>
      </slot>
      <slot name="box">
        <div
          class="overflow-hidden shadow-xl transform transition-all">
          <slot>
            <h3 class="bg-white rounded-lg shadow-xl p-6 text-center text-lg font-bold text-red-500">
              ! NO CONTENT !
            </h3>
          </slot>
        </div>
      </slot>
      <slot name="close">
        <button v-if="((isSlotEmpty(slots?.default) && !slots.box) || showDefaultClose)" class="fixed top-0 right-0 m-8" @click="(e: Event) => (model = false, emit('change', e))">
          <svg class="fill-black text-center w-8 h-8 scale-[1.0] hover:scale-[2.0] ease-in duration-200" viewBox="-1 -1 25 25" width="25" height="25" xmlns="http://www.w3.org/2000/svg">
            <title>Close</title>
            <path d="M18.278 16.864a1 1 0 01-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 01-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 011.414-1.414l4.829 4.828 4.828-4.828a1 1 0 111.414 1.414l-4.828 4.829 4.828 4.828z" />
          </svg>
        </button>
      </slot>
    </div>
  </transition>
</template>


<style lang="scss" scoped>
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transition: ease 0.2s;
}

.fade-leave-from,
.fade-enter-to {
  opacity: 1;
  transition: ease 0.2s;
}
</style>

<script setup lang="ts">
interface Props {
  value?: String | string | undefined;
  target?: '_blank' | '_self' | '_parent' | '_top' | undefined;
  href?: String | string | undefined;
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | null | undefined;
  block?: boolean | undefined;
  disabled?: boolean | undefined;
  styleName?: 'outline' | 'primary' | 'secondary' | 'default' | 'inverted' | 'muted' | 'title' | null | undefined;
  className?: String | string | undefined;
  rounded?: boolean | undefined;
  [x: string]: any;
}

const props = withDefaults(defineProps<Props>(), {
  rounded: true,
  styleName: 'default'
});

const styleSize = computed<String | string>(() => ((props.size !== undefined) ? props.size : useNuxtApp()?.$styleSize?.value));

const emit = defineEmits(['click', 'change']);

const sizes = {
  '2xl': 'px-8 py-4',
  xl: 'px-6 py-3',
  lg: 'px-5 py-2.5',
  md: 'px-4 py-2',
  sm: 'px-2 py-1',
  null: ''
};

const styles = {
  outline: 'text-black duration-500 ease-in-out placeholder:text-slate-500',
  primary: 'text-primary duration-500 ease-in-out placeholder:text-slate-500',
  secondary: 'text-secondary duration-500 ease-in-out placeholder:text-slate-500',
  default: 'text-slate-500 duration-500 ease-in-out placeholder:text-slate-500',
  inverted: 'text-black duration-500 ease-in-out placeholder:text-slate-500',
  muted: 'text-black duration-500 ease-in-out placeholder:text-slate-500',
  null: '',
};
</script>

<template>
  <NuxtLink v-if="href" :href="(!disabled ? href : null)" :target="target" :class="[
    (block ? 'w-full' : 'w-fit'),
    sizes[styleSize],
    (rounded && ((styleSize && (styleSize != 'sm')) ? ('rounded-' + styleSize) : 'rounded')),
    (styleSize && ('text-' + styleSize)),
    (!disabled && styles[styleName]),
    (!disabled && 'cursor-pointer'),
    (disabled && styles['muted']),
    className
  ]" :disabled="disabled" @click="(e: Event) => (!disabled ? emit('click', e) : null)"
    @change="(e: Event) => (!disabled ? emit('change', e) : null)">
    <slot>
      {{ value }}
    </slot>
  </NuxtLink>
  <button v-else :formtarget="target" :class="[
    (block ? 'w-full' : 'w-fit'),
    sizes[styleSize],
    (rounded && ((styleSize && (styleSize != 'sm')) ? ('rounded-' + styleSize) : 'rounded')),
    (styleSize && ('text-' + styleSize)),
    (!disabled && styles[styleName]),
    (!disabled && 'cursor-pointer'),
    (disabled && styles['muted']),
    className
  ]" :disabled="disabled" @click="(e: Event) => (!disabled ? emit('click', e) : null)"
    @change="(e: Event) => (!disabled ? emit('change', e) : null)">
    <slot>
      {{ value }}
    </slot>
  </button>
</template>

<script setup lang="ts">
interface Props {
  name?: String | string | undefined;
  errors?: Object | Array<String | string> | undefined;
};

const props = withDefaults(defineProps<Props>(), {});

const errorsTransformed = computed<Array<String | string>>(() => {
  if (props.errors)
    if (Array.isArray(props.errors))
      return props.errors;
    else if (String.isString(props.errors))
      return [props.errors];
    else if ((props.errors instanceof Object) && props.name)
      return [].concat(...Object.values(objectFilter(props.errors, (value, key) => ((key === props.name) || key.split('.').includes(props.name)))));
  return [];
});
</script>

<template>
  <div v-if="errorsTransformed?.length" class="flex flex-col gap-2 p-1">
    <div v-for="error of errorsTransformed" :key="error" v-show="String.isString(error)"
      class="flex flex-row gap-2 items-center">
      <span class="rounded-full w-2 h-2 bg-red-500" />
      <p class="text-red-500" v-text="error" />
    </div>
  </div>
</template>

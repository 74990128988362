import unhead_so8bOwvbe4 from "/var/www/html/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pdfps63yvxn7ebfx7mtldpmjdy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_YDxGjxuKDW from "/var/www/html/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pdfps63yvxn7ebfx7mtldpmjdy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_pgO4aQ3fLc from "/var/www/html/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.7_jiti@2.4._wvqv6yr6agqpkumb4fgfgckr3a/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import boot_OP3cd6SIk7 from "/var/www/html/plugins/boot.ts";
import window_mY4jZ4wBC5 from "/var/www/html/plugins/window.ts";
import payload_client_23oru7WsCk from "/var/www/html/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pdfps63yvxn7ebfx7mtldpmjdy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_7FDXsbXjYT from "/var/www/html/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pdfps63yvxn7ebfx7mtldpmjdy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_1skUY8bBWB from "/var/www/html/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pdfps63yvxn7ebfx7mtldpmjdy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/html/.nuxt/components.plugin.mjs";
import prefetch_client_JA3pYbC1eQ from "/var/www/html/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pdfps63yvxn7ebfx7mtldpmjdy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_o6DdcAOyjP from "/var/www/html/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.7_jiti@2.4.2_sass_2zyo4eg654zjltknu2uc2f7xcu/node_modules/@nuxt/icon/dist/runtime/plugin.js";
export default [
  unhead_so8bOwvbe4,
  router_YDxGjxuKDW,
  _0_siteConfig_pgO4aQ3fLc,
  boot_OP3cd6SIk7,
  window_mY4jZ4wBC5,
  payload_client_23oru7WsCk,
  navigation_repaint_client_7FDXsbXjYT,
  chunk_reload_client_1skUY8bBWB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_JA3pYbC1eQ,
  plugin_o6DdcAOyjP
]
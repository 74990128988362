<script setup lang="ts">
interface Props {
  styleName?: 'outline' | 'primary' | 'secondary' | 'default' | 'inverted' | 'muted' | null | undefined;
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | null | undefined;
  block?: boolean | undefined;
  disabled?: boolean | undefined;
  className?: String | string | undefined;
  [x: string]: any;
};

const props = withDefaults(defineProps<Props>(), {
  styleName: 'default'
});

const styleSize = computed<String | string>(() => ((props.size !== undefined) ? props.size : useNuxtApp()?.$styleSize?.value));

const styles = {
  outline: 'text-slate-500 duration-500 ease-in-out placeholder:text-slate-500',
  primary: 'text-primary duration-500 ease-in-out placeholder:text-slate-500',
  secondary: 'text-secondary duration-500 ease-in-out placeholder:text-slate-500',
  default: 'text-slate-500 duration-500 ease-in-out placeholder:text-slate-500',
  inverted: 'text-slate-500 duration-500 ease-in-out placeholder:text-slate-500',
  muted: 'text-slate-500 duration-500 ease-in-out placeholder:text-slate-500',
  null: ''
};
</script>

<template>
  <p :class="[
    (block ? 'w-full' : 'w-fit'),
    (styleSize && ('text-' + styleSize)),
    (!disabled && styles[styleName]),
    (disabled && styles['muted']),
    (className)
  ]">
    <slot />
  </p>
</template>
